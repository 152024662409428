import {navigate} from 'gatsby';
import {useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import {
    Tabs,
    Button,
    LoadingIndicator,
} from '../../../../components/core';
import {Layout, StatusBar} from '../../../../components/layout';
import {NavArrowLeftIcon} from '../../../../components/core/icons';
import {getVideoTabsData} from '../../../../configs/pages/videos/tabs';
import Timeline from '../../../../components/core/timeline/timeline';
import {
    GetVideoBasicInfoDocument,
    GetChangelogDocument,
    GetChangelogQuery,
} from '../../../../graphql-types';
import HistoryModal from '../../../../modals/videos/historyModal';
import * as layoutStyles from '../../../../components/layout/layout.module.css';

type GetVideoChangelogPageType = {
    video: string,
};

const VideoChangelogPage = ({
    video: videoId,
}: GetVideoChangelogPageType) => {
    const videoTabsData = useMemo(() => getVideoTabsData(undefined, true), []);
    const [openedModal, setModal] = useState<string | boolean>(false);
    const [changesetId, setChangesetId] = useState<string | null>(null);

    const {data: videoBasicInfo} = useQuery(
        GetVideoBasicInfoDocument,
        {variables: {videoId}},
    );

    const {loading, data} = useQuery<GetChangelogQuery>(GetChangelogDocument, {
        variables: {
            filter: {
                videoIds: [videoId],
            },
        },
        fetchPolicy: 'cache-and-network',
    });

    const videoTitle = videoBasicInfo ? videoBasicInfo.video?.mcbt : videoId;
    const pageTitle = `Video | ${videoTitle}`;

    return (
        <>
            <Layout
                title={pageTitle}
                scope="videos.write"
            >
                {loading && (
                    <LoadingIndicator/>
                )}
                <div className={layoutStyles.pageHeader}>
                    <StatusBar
                        buttons={(
                            <>
                                <Button
                                    icon={NavArrowLeftIcon}
                                    text="Back to videos"
                                    onClick={() => {
                                        navigate('../../');
                                    }}
                                    variant="outline"
                                />
                            </>
                        )}
                        title={(
                            <>
                                <span>Video /&nbsp;</span>
                                <strong>{videoTitle}</strong>
                            </>
                        )}
                    />
                </div>
                <div>
                    <Tabs
                        data={videoTabsData}
                    />
                </div>
                <div className={layoutStyles.pageContent}>
                    {!loading && data && (
                        <Timeline
                            data={data}
                            category='Video'
                            setModal={setModal}
                            setChangesetId={setChangesetId}
                            hasIds={false}
                            isVideoTimeline={true}
                        />
                    )}
                </div>
                {openedModal && (
                    <HistoryModal
                        setModal={setModal}
                        mutationId={openedModal || ''}
                        changesetId={changesetId}
                        hasIds={true}
                    />
                )}
            </Layout>
        </>
    );
};

export default VideoChangelogPage;

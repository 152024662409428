import {useQuery} from '@apollo/client';
import {
    Dispatch,
    SetStateAction,
} from 'react';
import {LoadingIndicator, Modal} from '../../components/core';
import Timeline from '../../components/core/timeline/timeline';
import {
    GetChangelogDocument,
    GetChangelogQuery,
    GetMutationNameQuery,
    GetMutationNameDocument,
} from '../../graphql-types';
import * as styles from './historyModal.module.css';

interface IHistoryModalProps {
    setModal: Dispatch<SetStateAction<string | boolean>>,
    mutationId: string | boolean,
    changesetId?: string | null,
    hasIds?: boolean,
}

const HistoryModal = ({
    setModal,
    mutationId,
    changesetId,
    hasIds,
}: IHistoryModalProps) => {
    const {loading, data} = useQuery<GetChangelogQuery>(GetChangelogDocument, {
        variables: {
            filter: {
                mutationIds: [mutationId],
            },
        },
        fetchPolicy: 'cache-and-network',
    });
    const {data: mutationData} = useQuery<GetMutationNameQuery>(GetMutationNameDocument, {
        variables: {
            mutationId,
        },
    });

    return (
        <Modal
            title={`Mutation "${mutationData?.mutation?.name ?? '<empty>'}" changelog`}
            closeModal={() => setModal(false)}
        >
            <>
                {loading && (
                    <div className={styles.loading}>
                        <LoadingIndicator/>
                    </div>
                )}
                {!loading && data && (
                    <Timeline
                        data={data}
                        category="Mutation"
                        changesetId={changesetId}
                        hasIds={hasIds}
                    />
                )}
            </>
        </Modal>
    );
};

export default HistoryModal;
